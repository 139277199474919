import { Box, Grid, Typography, Slider, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomHintPopover from "../../CustomHintPopover";
import { useSelector } from "react-redux";

const CallSettings = ({ handleChangeAssistantChangedData }) => {
  // const [callSettings, setCallSettings] = useState({
  //   max_duration: assistant?.ssml_data?.max_duration ?? 900,
  //   speech_rate: assistant?.ssml_data?.speech_rate ?? 15,
  // });

  // const handleChangeCallSettings = (name, newValue) => {
  //   setCallSettings((prevSettings) => ({
  //     ...prevSettings,
  //     [name]: newValue,
  //   }));
  // };

  const ssml_data = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo?.ssml_data
  );
  const updated_ssml_data = useSelector(
    (state) => state.assistantsDetails?.assistantUpdatedInfo?.ssml_data
  );

  const [ssmlDataExtraSetting, setSsmlDataExtraSetting] = useState({
    label: ssml_data?.label ?? "Aria",
    gender: ssml_data?.gender ?? "female",
    country: ssml_data?.country ?? "US",
    vtype: ssml_data?.vtype ?? "en-US-AriaNeural",
    tts_provider: ssml_data?.tts_provider ?? "azure",
    lang: ssml_data?.lang ?? "en",
    max_duration: ssml_data?.max_duration ?? 780,
    rengage_caller: ssml_data?.rengage_caller ?? true,
    rengage_count: ssml_data?.rengage_count ?? 2,
    no_input: ssml_data?.no_input ?? 22,
    speech_rate: ssml_data?.speech_rate ?? -12,
    rengage_message:
      ssml_data?.rengage_message ??
      "Sorry, I didn't get any response from you, are you still there?",
  });

  const handleChangeSetExtraSettings = (name, newValue) => {
    setSsmlDataExtraSetting((prevSettings) => ({
      ...prevSettings,
      [name]: newValue,
    }));
    handleChangeAssistantChangedData({
      ssml_data: {
        ...ssml_data,
        ...(updated_ssml_data || {}),
        [name]: newValue,
      },
    });
  };

  const assistantMachineDetection = useSelector(
    (state) =>
      state.assistantsDetails?.assistantInitialInfo?.additionalConfig
        ?.machine_detection
  );
  const assistantAdditionalConfigPresentInUpdatedData = useSelector(
    (state) => state.assistantsDetails?.assistantUpdatedInfo?.additionalConfig
  );

  const [machine_detection, setMachine_detection] = useState(
    handleChangeAssistantChangedData ?? false
  );
  const additionalConfigObj = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo?.additionalConfig
  );
  const handleChangeInAdditionalConfigData = (value) => {
    handleChangeAssistantChangedData({
      additionalConfig: {
        ...(additionalConfigObj || {}),
        ...(assistantAdditionalConfigPresentInUpdatedData || {}),
        ...value,
      },
    });
  };

  useEffect(() => {
    setMachine_detection(assistantMachineDetection ?? false);
  }, [assistantMachineDetection]);

  useEffect(() => {
    setSsmlDataExtraSetting({
      label: ssml_data?.label ?? "Aria",
      gender: ssml_data?.gender ?? "female",
      country: ssml_data?.country ?? "US",
      vtype: ssml_data?.vtype ?? "en-US-AriaNeural",
      tts_provider: ssml_data?.tts_provider ?? "azure",
      lang: ssml_data?.lang ?? "en",
      max_duration: ssml_data?.max_duration ?? 780,
      rengage_caller: ssml_data?.rengage_caller ?? true,
      rengage_count: ssml_data?.rengage_count ?? 2,
      no_input: ssml_data?.no_input ?? 22,
      speech_rate: ssml_data?.speech_rate ?? -12,
      rengage_message:
        ssml_data?.rengage_message ??
        "Sorry, I didn't get any response from you, are you still there?",
    });
  }, [ssml_data]);

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container>
        <Grid container item xs={12} mb={2}>
          <Grid item xs={12}>
            <Typography
              display="flex"
              alignItems="center"
              variant="subtitle2"
              fontWeight="bold"
            >
              Voicemail
              <Box mb={0.3}>
                <CustomHintPopover
                  size="small"
                  maxWidth={400}
                  transformHorizontalPosition={"left"}
                  transformVerticalPosition={"bottom"}
                  anchorHorizontalPosition={"left"}
                  anchorVerticalPosition={"top"}
                  hintContent={
                    <>
                      <p>
                        <strong>Voicemail Detection: </strong>Toggle to enable
                        or disable answering machine detection for outbound
                        calls.
                      </p>
                      <br />
                      <p>
                        <strong>Enabled: </strong>Detects answering machines or
                        voicemails and disconnects the call without deducting
                        minutes from your quota.
                      </p>
                      <br />
                      <p>
                        <strong>Disabled: </strong>All calls proceed without
                        detection.
                      </p>
                      <br />
                      <p>
                        <strong>
                          Note:{" "}
                          <p>
                            This feature is currently disabled for{" "}
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              Plivo Dialer
                            </span>
                            .
                          </p>
                        </strong>
                      </p>
                    </>
                  }
                />
              </Box>
            </Typography>
            <Typography variant="caption">
              Detects answering machines or voicemails and disconnects the call
              without deducting minutes from your quota.
            </Typography>
          </Grid>
          <Grid item>
            <Switch
              size="medium"
              checked={machine_detection ?? false}
              onChange={(event) => {
                handleChangeInAdditionalConfigData({
                  machine_detection: event.target.checked,
                });
                setMachine_detection(event.target.checked);
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} mb={2}>
          <Grid item xs={12}>
            <Typography
              display="flex"
              alignItems="center"
              variant="subtitle2"
              fontWeight="bold"
            >
              Max Call Duration:
              <CustomHintPopover
                size="small"
                maxWidth={400}
                transformHorizontalPosition={"left"}
                transformVerticalPosition={"bottom"}
                anchorHorizontalPosition={"left"}
                anchorVerticalPosition={"top"}
                hintContent="Set a time limit for your calls. The voice assistant will end the call once this duration is reached."
              />
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={8} display="flex" alignItems="center">
              <Slider
                size="small"
                valueLabelDisplay="auto"
                value={ssmlDataExtraSetting?.max_duration / 60}
                step={1}
                onChange={(e, newValue) =>
                  handleChangeSetExtraSettings("max_duration", newValue * 60)
                }
                aria-labelledby="max-duration-slider"
                min={5}
                max={20}
              />
            </Grid>
            <Grid item display="flex" alignItems="center">
              <Typography variant="subtitle2" fontWeight="bold">
                ({ssmlDataExtraSetting?.max_duration / 60} minutes)
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CallSettings;
