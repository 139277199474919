import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import initialState from "./initialState";
import { fetchDataAndProceedWithToolkit } from "../helpers";
import { METHOD_TYPES, LOCAL_STORAGE } from "../../configs/constants";
import { setDataInLocalStorage } from "../../configs/utils";
import {
  FORGOT_PASSWORD,
  LOGIN_USER,
  LOGOUT_USER,
  RESET_PASSWORD,
  SIGNUP_USER,
  SIGNUP_PASSWORD,
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  VERIFY_EMAIL,
  SEND_VERIFICATION_TOKEN,
  SUBMIT_USER_ONBOARDING_SURVEY,
  SEND_OTP_TO_VERIFY_MOBILE_NUMBER,
  VERIFY_OTP,
  PLACE_DEMO_CALL,
  GET_PRICING_DETAILS,
  GET_USER_PLAN,
  UPDATE_USER_PLAN,
  ENTERPRISE,
  GET_DEAL_PLANS,
  FEEDBACK_FORM,
  APP_SUMO_ASSIST,
  GET_USER_PLAN_EXTRA_DETAILS,
  CHANGE_PASSWORD,
  CANCEL_SUBSCRIPTION,
  POST_PRICING_INVOICE_PRIVIEW_PROCEED,
  POST_PRICING_INVOICE_PREVIEW,
  VIEW_USER_INVOICE,
  CHANGE_DETAILS,
} from "./api";

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateAuthState(state, action) {
      if (action.payload.user)
        setDataInLocalStorage(LOCAL_STORAGE.APP_USER, action.payload.user);
      return { ...state, ...action.payload };
    },
    updateAuthStateForDemoCall(state, action) {
      const updatedUser = {
        ...state.user,
        ...action.payload,
      };
      return { ...state, user: updatedUser };
    },
    resetReduxState() {
      return;
    },
    updateUserPhoneNumber(state, action) {
      if (action.payload && action.payload.phoneNumber) {
        const updatedUser = {
          ...state.user,
          phoneNumber: action.payload.phoneNumber,
        };
        setDataInLocalStorage(LOCAL_STORAGE.APP_USER, updatedUser);
        return {
          ...state,
          user: updatedUser,
        };
      }
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signupPassword.fulfilled, (state, { payload }) => {
        if (payload.data && payload.data.token && payload.data.user) {
          state.token = payload.data.token;
          state.user = payload.data.user;
          setDataInLocalStorage(LOCAL_STORAGE.IS_NEW_USER, true);
          setDataInLocalStorage(LOCAL_STORAGE.AUTH_TOKEN, payload.data.token);
          setDataInLocalStorage(LOCAL_STORAGE.APP_USER, payload.data.user);
        }
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        if (payload.data && payload.data.token && payload.data.user) {
          state.token = payload.data.token;
          state.user = payload.data.user;
          setDataInLocalStorage(LOCAL_STORAGE.AUTH_TOKEN, payload.data.token);
          setDataInLocalStorage(LOCAL_STORAGE.APP_USER, payload.data.user);
        }
      })
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        if (payload?.data?.user) {
          state.user = payload.data.user;
          setDataInLocalStorage(LOCAL_STORAGE.APP_USER, payload.data.user);
        }
      })
      .addCase(updateUserProfile.fulfilled, (state, { payload }) => {
        if (payload?.data?.updatedUser) {
          state.user = payload.data.updatedUser;
          if (payload?.data?.updatedUser?.picture)
            state.user.picture = `${
              payload?.data?.updatedUser?.picture
            }?timestamp=${new Date().getTime()}`;
          setDataInLocalStorage(
            LOCAL_STORAGE.APP_USER,
            payload.data.updatedUser
          );
        }
      })
      .addCase(getPricingDetails.fulfilled, (state, { payload }) => {
        if (payload?.data?.plans) {
          state.plans = payload.data.plans;
        }
        if (payload?.data?.addOnPlans) {
          state.addOnPlans = payload.data.addOnPlans;
        }
        if (payload?.data?.oneTimePlans) {
          state.oneTimePlans = payload.data.oneTimePlans;
        }
        if (payload?.data?.bannerInfo) {
          state.bannerInfo = payload.data.bannerInfo
        }
        if (payload?.data?.oneTimePlans || payload?.data?.addOnPlans) {
          state.addOnPlansWholeData = [
            ...(payload?.data?.oneTimePlans || []),
            ...(payload?.data?.addOnPlans || []),
          ];
        }
      })
      .addCase(getUserPlan.fulfilled, (state, { payload }) => {
        if (payload?.data?.subscription) {
          state.subscription = payload.data.subscription;
          state.userQuota = payload?.data?.userQuota;
        }
        if (payload?.data?.currentAddOnPlans) {
          state.currentAddOnPlans = payload.data.currentAddOnPlans;
        }
      })
      .addCase(updateUserPlan.fulfilled, (state, { payload }) => {
        if (payload?.data?.plans) {
          state.plans = payload.data.plans;
        }
        if (payload?.data?.addOnPlans) {
          state.addOnPlans = payload.data.addOnPlans;
        }
        if (payload?.data?.oneTimePlans) {
          state.oneTimePlans = payload.data.oneTimePlans;
        }
        if (payload?.data?.oneTimePlans || payload?.data?.addOnPlans) {
          state.addOnPlansWholeData = [
            ...(payload?.data?.oneTimePlans || []),
            ...(payload?.data?.addOnPlans || []),
          ];
        }
      })
      .addCase(placeDemoCall.fulfilled, (state, { payload }) => {
        if (payload?.status === "success") {
          state.demoCallSuccessfull = true;
        }
      });
  },
});

export const signupUser = createAsyncThunk(
  "auth/signupUser",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: SIGNUP_USER,
        method: METHOD_TYPES.POST,
        data,
        authLoader: true,
      },
      helpers
    )
);
export const appSumoAssist = createAsyncThunk(
  "auth/appSumoAssist",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: APP_SUMO_ASSIST,
        method: METHOD_TYPES.POST,
        data,
        authLoader: true,
      },
      helpers
    )
);

export const signupPassword = createAsyncThunk(
  "auth/signupPassword",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: SIGNUP_PASSWORD,
        method: METHOD_TYPES.POST,
        data,
        authLoader: true,
      },
      helpers
    )
);

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: LOGIN_USER,
        method: METHOD_TYPES.POST,
        data,
        authLoader: true,
      },
      helpers
    )
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: FORGOT_PASSWORD,
        method: METHOD_TYPES.POST,
        data,
        authLoader: true,
      },
      helpers
    )
);
export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: CHANGE_PASSWORD,
        method: METHOD_TYPES.POST,
        data,
        authLoader: true,
        loader: false,
        modalLoader: true,
      },
      helpers
    )
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: RESET_PASSWORD,
        method: METHOD_TYPES.POST,
        data,
        authLoader: true,
      },
      helpers
    )
);

export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: LOGOUT_USER,
        data,
        loaderText: "Logging Out",
      },
      helpers
    )
);

export const getUserProfile = createAsyncThunk(
  "auth/getUserProfile",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_USER_PROFILE,
        loaderText: "Fetching User Profile",
        loader: !data?.showLoader,
        data,
      },
      helpers
    )
);

export const updateUserProfile = createAsyncThunk(
  "auth/updateUserProfile",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: UPDATE_USER_PROFILE,
        method: METHOD_TYPES.PUT,
        contentType: "multipart/form-data",
        data,
        loader: false,
        modalLoader: true,
      },
      helpers
    )
);

export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: VERIFY_EMAIL,
        loaderText: "Verifying",
        data,
        loader: false,
      },
      helpers
    )
);

export const sendVerificationToken = createAsyncThunk(
  "auth/sendVerificationToken",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: SEND_VERIFICATION_TOKEN,
        loader: false,
        data,
        method: METHOD_TYPES.POST,
        modalLoader: true,
      },
      helpers
    )
);

export const submitUserOnBoardingSurvey = createAsyncThunk(
  "auth/submitUserOnBoardingSurvey",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: SUBMIT_USER_ONBOARDING_SURVEY,
        loaderText: "Submitting",
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
);

export const getOtpToVerifyMobileNumber = createAsyncThunk(
  "assistants/getOtpToVerifyMobileNumber",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: SEND_OTP_TO_VERIFY_MOBILE_NUMBER,
        method: METHOD_TYPES.POST,
        data,
        modalLoader: true,
      },
      helpers
    )
);

export const verifyOTP = createAsyncThunk(
  "assistants/verifyOTP",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: VERIFY_OTP,
        method: METHOD_TYPES.POST,
        data,
        modalLoader: true,
      },
      helpers
    )
);

export const placeDemoCall = createAsyncThunk(
  "assistants/placeDemoCall",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: PLACE_DEMO_CALL,
        method: METHOD_TYPES.POST,
        data,
        modalLoader: true,
      },
      helpers
    )
);

export const getPricingDetails = createAsyncThunk(
  "auth/getPricingDetails",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_PRICING_DETAILS,
      },
      helpers
    )
);

export const getUserPlan = createAsyncThunk(
  "auth/getUserPlan",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_USER_PLAN,
      },
      helpers
    )
);

export const getUserPlanExtraDetails = createAsyncThunk(
  "auth/billing/fetchUserQuota",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_USER_PLAN_EXTRA_DETAILS,
      },
      helpers
    )
);

export const updateUserPlan = createAsyncThunk(
  "auth/updateUserPlan",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: UPDATE_USER_PLAN,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
);

export const changePaymentDetails = createAsyncThunk(
  "billing/handleSubscriptionActions",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit({
      url: CHANGE_DETAILS,
      method: METHOD_TYPES.POST,
      data
    }, helpers)
)
export const invoicePriview = createAsyncThunk(
  "/billing/invoicePreview",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: POST_PRICING_INVOICE_PREVIEW,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
);
export const handleSubscriptionActions = createAsyncThunk(
  "/billing/handleSubscriptionActions",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: POST_PRICING_INVOICE_PRIVIEW_PROCEED,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
);

export const bookEnterpriseMeeting = createAsyncThunk(
  "auth/bookEnterpriseMeeting",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: ENTERPRISE,
        method: METHOD_TYPES.POST,
        data,
        modalLoader: true,
      },
      helpers
    )
);

export const getInvoice = createAsyncThunk(
  "billing/getInvoice",
  async (data = {}, helpers) => 
    fetchDataAndProceedWithToolkit(
      {
        url: VIEW_USER_INVOICE,
        loader: true,
        data
      },
      helpers
    )
)

export const getDealPlans = createAsyncThunk(
  "auth/getDealPlans",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_DEAL_PLANS,
        loader: false,
      },
      helpers
    )
);

export const sendFeedback = createAsyncThunk(
  "user/sendFeedback",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: FEEDBACK_FORM,
        method: METHOD_TYPES.POST,
        data,
        authLoader: true,
      },
      helpers
    )
);

export const cancelSubcription = createAsyncThunk(
  "user/cancelSubscription",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: CANCEL_SUBSCRIPTION,
        method: METHOD_TYPES.POST,
        data,
        authLoader: true,
      },
      helpers
    )
);

export const {
  updateAuthState,
  resetReduxState,
  updateUserPhoneNumber,
  updateAuthStateForDemoCall,
} = authSlice.actions;
export default authSlice.reducer;
