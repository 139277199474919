import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePageState } from "../../data/store/pageSlice";
import { APP_PAGES, ROUTE_PATH } from "../../data/configs/constants";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import * as _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import chatbotLogo from "../../data/assets/img/chatbot_logo.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import {
  handleCleverTapBtnClick,
  log,
  parseQueryString,
} from "../../data/configs/utils";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone.js";
import dayjs from "dayjs";
import { getCallDetails } from "../../data/store/campaignsSlice";
import CustomHintPopover from "../CustomHintPopover";
import useAudioStreamTwilio from "../../data/hooks/useAudioStreamTwilio";
import ExtraCallDetails from "./ExtraCallDetails";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
dayjs.extend(utc);
dayjs.extend(timezone);

const CallDetailTranscript = () => {
  const navigate = useNavigate();
  const restParams = useLocation();
  const token = useSelector((state) => state.authDetails.token);
  const restParamsObj = parseQueryString(restParams?.search);
  const [contactData, setContactData] = useState({});
  const [audioUrlPresent, setAudioUrlPresent] = useState("");
  const dispatch = useDispatch();

  const { audio, error } = useAudioStreamTwilio(
    audioUrlPresent,
    restParamsObj,
    token
  );
  const stateData = restParams.state;

  useEffect(() => {
    dispatch(
      updatePageState({
        currentPage: APP_PAGES.CALL_HISTORY_AND_CAMPAIGNS_CALL_DETAIL.PAGE,
        currentActiveModule: stateData?.callHistory
          ? APP_PAGES.CALL_HISTORY.PAGE
          : APP_PAGES.CAMPAIGNS.PAGE,
      })
    );
  }, [dispatch, restParams]);

  const copyToClipboard = () => {
    const textToCopy = contactData?.transcript
      .map((item) => `${item.timestamp} - ${item.sender}: ${item.message}`)
      .join("\n");
    navigator.clipboard.writeText(textToCopy);
  };

  const getCallDetailsData = (params) => {
    dispatch(getCallDetails({ ...params }))
      .unwrap()
      .then((res) => {
        if (res?.data?.call) {
          setContactData(res?.data?.call);
          setAudioUrlPresent(res?.data?.call?.recordingUri);
        }
      })
      .catch((err) => {
        log("ERR-getcallDetails: ", err);
      });
  };

  useEffect(() => {
    if (!_.isEmpty(restParamsObj)) {
      getCallDetailsData({ ...restParamsObj });
    }
  }, [restParams]);

  const LoadingSkeleton = () => {
    return (
      <div
        style={{
          width: "100%",
          padding: "20px",
          backgroundColor: "#f0f0f0",
          borderRadius: "24px",
          textAlign: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "15px",
            backgroundColor: "#e0e0e0",
            borderRadius: "24px",
          }}
        ></div>
      </div>
    );
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{ display: "flex", alignItems: "center", gap: 2 }}
        >
          {restParamsObj?.campaignId && restParamsObj?.campaignId !== "demo" ? (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                navigate(
                  ROUTE_PATH.EDIT_CAMPAIGN.replace(
                    ":campaignId",
                    restParamsObj?.campaignId
                  )
                );
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          ) : null}
          <Typography
            marginTop={1}
            align="start"
            ml={1}
            mb={1}
            sx={{ fontWeight: "bold" }}
            fontSize="bold"
            variant="h5"
          >
            {contactData?.Name?.length > 0
              ? contactData?.Name
              : contactData?.customerNumber}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={8}
          sx={{
            paddingRight: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Card
            sx={{
              padding: 2,
              maxHeight: "85vh",
              minHeight: "85vh",
              display: "flex",
              justifyContent: "space-between",
              boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.2)",
              overflow: "auto",
            }}
          >
            <Grid
              container
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item xs={12} mb={2} display="flex" alignItems="center">
                <Card
                  sx={{
                    padding: 2,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 0px 1px 0px",
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} mb={1}>
                      <Typography
                        display="flex"
                        alignItems="center"
                        variant="h6"
                        marginLeft={2}
                        sx={{ fontWeight: "bold" }}
                      >
                        Details
                        <CustomHintPopover
                          size="small"
                          maxWidth={400}
                          // hintTitle={'Bot Temperature Setting'}
                          transformHorizontalPosition={"left"}
                          transformVerticalPosition={"center"}
                          anchorHorizontalPosition={"right"}
                          anchorVerticalPosition={"top"}
                          hintContent={
                            "Additional information about your call."
                          }
                        />
                      </Typography>
                    </Grid>
                    <Grid container marginInline={2} spacing={2}>
                      <Grid item xs={7}>
                        <Grid mb={2}>
                          <Typography
                            variant="body1"
                            style={{ fontweight: "bold" }}
                          >
                            <strong style={{ paddingRight: "4px" }}>
                              Call Duration :
                            </strong>{" "}
                            {contactData?.duration || "NA"}
                          </Typography>
                        </Grid>
                        <Grid mb={2}>
                          <Typography variant="body1">
                            <strong style={{ paddingRight: "4px" }}>
                              Start Time :
                            </strong>
                            {dayjs(contactData?.initiationTime).isValid()
                              ? dayjs
                                  .unix(contactData?.initiationTime / 1000)
                                  ?.format("h:mm:ss a")
                              : "NA"}
                          </Typography>
                        </Grid>
                        {contactData?.HangupCause?.length > 0 && (
                          <Grid mb={2}>
                            <Typography variant="body1">
                              <strong style={{ paddingRight: "4px" }}>
                                Hangup Reason :
                              </strong>{" "}
                              {contactData?.HangupCause}
                            </Typography>
                          </Grid>
                        )}
                        {contactData?.customerNumber && (
                          <Grid mb={2}>
                            <Typography variant="body1">
                              <strong style={{ paddingRight: "4px" }}>
                                Customer Number :
                              </strong>{" "}
                              {contactData?.customerNumber}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>

                      <Grid item xs={5}>
                        <Grid mb={2}>
                          <Typography variant="body1">
                            <strong style={{ paddingRight: "4px" }}>
                              Date :
                            </strong>
                            {dayjs(contactData?.initiationTime).isValid()
                              ? dayjs
                                  .unix(contactData?.initiationTime / 1000)
                                  .tz("Asia/Kolkata")
                                  .format("DD MMMM YYYY")
                              : "NA"}
                          </Typography>
                        </Grid>
                        <Grid mb={2}>
                          <Typography variant="body1">
                            <strong style={{ paddingRight: "4px" }}>
                              End Time :
                            </strong>
                            {dayjs(contactData?.endTime).isValid()
                              ? dayjs(contactData?.endTime).format("h:mm:ss a")
                              : "NA"}
                          </Typography>
                        </Grid>
                        {contactData?.HangupSource?.length > 0 && (
                          <Grid mb={2}>
                            <Typography variant="body1">
                              <strong style={{ paddingRight: "4px" }}>
                                Hangup By :
                              </strong>{" "}
                              {contactData?.HangupSource}
                            </Typography>
                          </Grid>
                        )}
                        {contactData?.callStatus?.length > 0 && (
                          <Grid mb={2}>
                            <Typography variant="body1">
                              <strong style={{ paddingRight: "4px" }}>
                                Status :
                              </strong>
                              {contactData?.callStatus.charAt(0).toUpperCase() +
                                contactData?.callStatus.slice(1)}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} mb={2} display="flex" alignItems="center">
                <Card
                  sx={{
                    padding: 2,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 0px 1px 0px",
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} mb={1}>
                      <Typography
                        display="flex"
                        alignItems="center"
                        variant="h6"
                        marginLeft={2}
                        sx={{ fontWeight: "bold" }}
                      >
                        Call Recording
                        <CustomHintPopover
                          size="small"
                          maxWidth={400}
                          // hintTitle={'Bot Temperature Setting'}
                          transformHorizontalPosition={"left"}
                          transformVerticalPosition={"center"}
                          anchorHorizontalPosition={"right"}
                          anchorVerticalPosition={"top"}
                          hintContent={"Recording of whole conversation."}
                        />
                      </Typography>
                    </Grid>
                    <Grid container display="flex" justifyContent="center">
                      <Grid item xs={12} paddingInline={2}>
                        {contactData?.recordingUri ? (
                          restParamsObj.campaignId !== "demo" ? (
                            audio ? (
                              _.isEmpty(error) ? (
                                <audio
                                  key={audio}
                                  preload="auto"
                                  controls
                                  style={{ width: "100%" }}
                                  onPlay={() =>
                                    handleCleverTapBtnClick(
                                      "Audio Recording Played"
                                    )
                                  }
                                >
                                  <source src={audio} type="audio/mp3" />
                                  Your browser does not support the audio
                                  element.
                                </audio>
                              ) : (
                                <Box
                                  sx={{
                                    minHeight: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography>
                                    Recording Not Available!
                                  </Typography>
                                </Box>
                              )
                            ) : (
                              <LoadingSkeleton />
                            )
                          ) : (
                            <Box
                              component="audio"
                              src={contactData?.recordingUri}
                              width="100%"
                              controls
                              onPlay={() =>
                                handleCleverTapBtnClick(
                                  "Audio Recording Played"
                                )
                              }
                            />
                          )
                        ) : (
                          <Box
                            sx={{
                              minHeight: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography>Recording Not Available!</Typography>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              {contactData?.outcome?.length > 0 ? (
                <Grid item xs={12} mb={2} display="flex" alignItems="center">
                  <Card
                    sx={{
                      padding: 2,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 0px 1px 0px",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} mb={1}>
                        <Typography
                          display="flex"
                          alignItems="center"
                          variant="h6"
                          marginLeft={2}
                          sx={{ fontWeight: "bold" }}
                        >
                          Post Call Analysis
                          <CustomHintPopover
                            size="small"
                            maxWidth={400}
                            // hintTitle={'Bot Temperature Setting'}
                            transformHorizontalPosition={"left"}
                            transformVerticalPosition={"center"}
                            anchorHorizontalPosition={"right"}
                            anchorVerticalPosition={"top"}
                            hintContent={
                              "Answer to questions asked during campaign creation."
                            }
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} paddingInline={2}>
                        {contactData?.outcome?.map((item, index) => (
                          <Grid
                            container
                            mb={1}
                            mt={2}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                            key={index}
                          >
                            <Grid item xs={1.5}>
                              <Typography variant="body1">
                                {`Question${index + 1}`} :
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                sx={{ fontWeight: "bold" }}
                                variant="body1"
                              >
                                {item?.Question}
                              </Typography>
                            </Grid>
                            <Grid item xs={1.5}>
                              <Typography variant="body1">Answer : </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography
                                sx={{ fontWeight: "bold" }}
                                variant="body1"
                              >
                                {item?.Answer}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ) : null}

              {contactData &&
              Object.keys(contactData.Entities ?? {})?.some(
                (key) => contactData?.Entities[key]?.length > 0
              ) ? (
                <Grid item xs={12} mb={2} display="flex" alignItems="center">
                  <Card
                    sx={{
                      padding: 2,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 0px 1px 0px",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} mb={1}>
                        <Typography
                          display="flex"
                          alignItems="center"
                          variant="h6"
                          marginLeft={2}
                          sx={{ fontWeight: "bold" }}
                        >
                          Callee Information Collected from the conversation
                          <CustomHintPopover
                            size="small"
                            maxWidth={400}
                            // hintTitle={'Bot Temperature Setting'}
                            transformHorizontalPosition={"left"}
                            transformVerticalPosition={"center"}
                            anchorHorizontalPosition={"right"}
                            anchorVerticalPosition={"top"}
                            hintContent={
                              "Information gathered from the conversation."
                            }
                          />
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        paddingInline={2}
                      >
                        {Object.entries(contactData.Entities).map(
                          ([key, value], index) =>
                            value.length > 0 && (
                              <Grid item mb={1} mt={2} key={index}>
                                <Typography variant="h6" mr={2}>
                                  {key.charAt(0).toUpperCase() + key.slice(1)}:
                                </Typography>
                                <div
                                  style={{
                                    borderRadius: "4px",
                                    paddingBlock: 4,
                                    paddingInline: 6,
                                    backgroundColor: "#0055FD",
                                    color: "#fff",
                                    textAlign: "center",
                                    userSelect: "none",
                                  }}
                                >
                                  {value}
                                </div>
                              </Grid>
                            )
                        )}
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ) : null}
              {contactData && contactData?.meetingInfo?.length > 0 ? (
                <Grid item xs={12} mb={2} display="flex" alignItems="center">
                  <Card
                    sx={{
                      padding: 2,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 0px 1px 0px",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} mb={1}>
                        <Typography
                          display="flex"
                          alignItems="center"
                          variant="h6"
                          marginLeft={2}
                          sx={{ fontWeight: "bold" }}
                        >
                          Meeting Information
                          <CustomHintPopover
                            size="small"
                            maxWidth={400}
                            // hintTitle={'Bot Temperature Setting'}
                            transformHorizontalPosition={"left"}
                            transformVerticalPosition={"center"}
                            anchorHorizontalPosition={"right"}
                            anchorVerticalPosition={"top"}
                            hintContent={
                              "Information gathered from the user conversation."
                            }
                          />
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        paddingInline={2}
                      >
                        {contactData?.meetingInfo?.map((obj, index) => (
                          <Grid
                            item
                            xs={12}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            container
                            mb={1}
                            mt={2}
                            key={index}
                          >
                            <Typography variant="subtitle1" mr={2}>
                              {obj?.key}:
                            </Typography>
                            {obj?.chip ? (
                              obj?.value === true ? (
                                <Chip
                                  size="small"
                                  label="True"
                                  color="success"
                                />
                              ) : (
                                <Chip
                                  size="small"
                                  label="False"
                                  color="warning"
                                />
                              )
                            ) : obj?.value === true ? (
                              "True"
                            ) : (
                              "False"
                            )}
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ) : null}
              {!_.isNil(contactData.averageResponseTime) ||
              !_.isNil(contactData.isUserInterested) ||
              !_.isEmpty(contactData.chatLanguage) ||
              !_.isEmpty(contactData.chatSummary) ? (
                <ExtraCallDetails
                  averageResponseTime={_.defaultTo(
                    contactData.averageResponseTime,
                    "N/A"
                  )}
                  isUserInterested={_.defaultTo(
                    contactData.isUserInterested,
                    "N/A"
                  )}
                  chatLanguage={_.defaultTo(contactData.chatLanguage, "N/A")}
                  chatSummary={_.defaultTo(contactData.chatSummary, "N/A")}
                />
              ) : null}
            </Grid>
          </Card>
        </Grid>

        <Grid xs={4} item>
          <Card
            variant="outlined"
            sx={{
              maxHeight: "85vh",
              minHeight: "85vh",
              boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.2)",
              padding: 2,
              "& .MuiCardHeader-title": {
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                marginBottom: 1,
              },
              "& .MuiCardHeader-root": { padding: 0, mb: 1 },
              "& .MuiCardContent-root": { padding: 0 },
              "& .MuiCardContent-root:last-child": { padding: 0 },
            }}
          >
            <CardHeader
              title={
                <Typography
                  display="flex"
                  alignItems="center"
                  variant="h6"
                  marginLeft={2}
                  sx={{ fontWeight: "bold" }}
                >
                  Transcript
                  <CustomHintPopover
                    size="small"
                    maxWidth={400}
                    // hintTitle={'Bot Temperature Setting'}
                    transformHorizontalPosition={"left"}
                    transformVerticalPosition={"center"}
                    anchorHorizontalPosition={"right"}
                    anchorVerticalPosition={"top"}
                    hintContent={"Transcript for the call."}
                  />
                </Typography>
              }
              action={
                contactData?.transcript &&
                contactData?.transcript?.length > 0 && (
                  <Tooltip title="Copy Transcript" placement="top">
                    <IconButton
                      onClick={copyToClipboard}
                      aria-label="copyToClipboard"
                    >
                      <FileCopyOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )
              }
            />

            <CardContent>
              <Box
                sx={{
                  overflowY: "auto",
                  maxHeight: "75vh",
                  minHeight: "75vh",
                }}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                {contactData?.transcript &&
                contactData?.transcript?.length > 0 ? (
                  <Grid container style={{ width: "100%" }}>
                    {contactData?.transcript.map((item, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={12}>
                          <Typography
                            component="div"
                            fontWeight="bold"
                            fontSize="0.5rem"
                            align="center"
                            gutterBottom
                          >
                            {dayjs(item?.timestamp).isValid()
                              ? dayjs(item?.timestamp).format(" h:mm:ss a")
                              : ""}
                          </Typography>
                        </Grid>
                        {item.sender === "BOT" ? (
                          <Grid
                            item
                            xs={10}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              marginBottom: "8px",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={chatbotLogo}
                              alt="Chatbot Logo"
                              style={{
                                width: "30px",
                                height: "30px",
                                marginRight: "8px",
                                verticalAlign: "middle",
                              }}
                            />
                            <Box
                              style={{
                                backgroundColor: "#0055FD",
                                color: "white",
                                padding: "8px",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography variant="subtitle2">
                                {item.message}
                              </Typography>
                            </Box>
                          </Grid>
                        ) : (
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginBottom: "8px",
                            }}
                          >
                            <Box
                              style={{
                                backgroundColor: "#35FFA1",
                                padding: "8px",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography variant="subtitle2">
                                {item.message}
                              </Typography>
                            </Box>
                            <IconButton size="small">
                              <AccountCircleIcon />
                            </IconButton>
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                  </Grid>
                ) : (
                  <Box
                    sx={{
                      minHeight: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography>Transcript Not Available!</Typography>
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CallDetailTranscript;
