export const GET_ALL_CAMPAIGNS = "/campaigns/getAllWorkspaceCampaign"
export const CREATE_CAMPAIGN = "/campaigns/create"
export const CAMPAIGN_COUNT = "/campaigns/campaignCount"
export const DOWNLOAD_CAMPAIGN_DATA = "/campaigns/downloadCampaignData"
export const DOWNLOAD_CONTACT_LIST = '/campaigns/exportContacts'
export const UPDATE_CAMPAIGN = "/campaigns/edit"
export const DELETE_CAMPAIGN = "/campaigns/delete"
export const START_CAMPAIGN = "/campaigns/start"
export const GET_CAMPAIGN_OVERVIEW_DETAILS = "/campaigns/campaignProgress"
export const GET_CALL_DETAILS = "/campaigns/getCallDetails"
export const GET_CALL_RECORDING_DETAILS = "campaigns/callRecording"
export const GET_ACTIVE_CALL_DATA = "/campaigns/getActiveCalls"
export const GET_CAMPAIGN_ANALYTICS_DATA = "/campaigns/getCampaignAnalytics"
export const FORCE_STOP_CAMPAIGN = "/campaigns/forceStopCampaign"
